import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'register/:aff',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
      ],
    },

    // MY PROFILE
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <MyProfile /> },
        { path: 'edit-profile', element: <MyProfileEdit /> },
      ],
    },

    // My Transaction
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'my-transactions', element: <MyTransaction /> },
      ],
    },

    // ACCOMODATION
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'accomodation', element: <Accomodation /> },
      ],
    },

    // WIN/LOSS
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'win-loss', element: <WinLossSummary /> },
      ],
    },

    // REWARD
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'rewards', element: <Reward /> },
      ],
    },

    // Download Page
    {
      path: 'download',
      element: <DownloadPage />,
    },

    // -------------------------------------------------------------------------------------------

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: 'ios/top1abc.ipa', element: <TriggerDownload file={'top1abc.ipa'} /> },
        { path: 'ios/manifest.plist', element: <TriggerDownload file={'manifest.plist'} /> },
        { path: 'android/top1abc.apk', element: <TriggerDownload file={'top1abc.apk'} /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

// MY PROFILE
const MyProfile = Loadable(lazy(() => import('../views/myProfile/MyProfile')));
const MyProfileEdit = Loadable(lazy(() => import('../views/myProfile/MyProfileEdit')));

// MY TRANSACTION
const MyTransaction = Loadable(lazy(() => import('../views/myTransaction/MyTransaction')));

// ACCOMODATION
const Accomodation = Loadable(lazy(() => import('../views/accomodation/Accomodation')));

// WIN / LOSS REPORT
const WinLossSummary = Loadable(lazy(() => import('../views/winLossReport/WinLoss')));

// REWARD
const Reward = Loadable(lazy(() => import('../views/reward/Rewards')));

// DOWNLOAD PAGE
const DownloadPage = Loadable(lazy(() => import('../views/DownloadPage')));
const TriggerDownload = Loadable(lazy(() => import('../views/TriggerDownload')));

//----------------------------------------------------------------------------------------------------------------------

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
