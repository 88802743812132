import React, { useEffect, useMemo, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';
import CustomModal from '../../../components/CustomModal';
import useResponsive from '../../../hooks/useResponsive';
import QRCodeReader from '../../../components/QrCodeReader';
import conObj from '../../../utils/connection-assistant';
import { getResponseError } from '../../../utils/formatObject';
import { openConfirm } from '../../../redux/slices/confirm';
import { fetchMyGroups } from '../../../redux/slices/lookup';
import useLocales from '../../../hooks/useLocales';

export const QrCodePopover = () => {
  const { groups } = useSelector((x) => x.lookup);

  const { translate } = useLocales();

  const [openQrReader, setOpenQrReader] = useState(false);

  const [qrCode, setQrCode] = useState('');

  const isMdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const hasActiveGroup = useMemo(() => {
    if (groups?.length) {
      const _hasActiveGroup = groups.findIndex((g) => g.isEnded === false);
      if (_hasActiveGroup === -1) return false;
      return true;
    }

    return false;
  }, [groups]);

  const joinGroup = async (_qrCode) => {
    try {
      await conObj.post('Member/JoinGroup', { joinGroupQrcode: _qrCode });

      enqueueSnackbar('Join Group Successful!', { variant: 'success' });
      fetchMyGroups();
    } catch (error) {
      enqueueSnackbar(getResponseError(error), { variant: 'error' });
    } finally {
      sessionStorage.removeItem('groupQrCode');
    }
  };

  useEffect(() => {
    if (!groups?.length) fetchMyGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedQrCode = sessionStorage.getItem('groupQrCode');
    const hasAsked = sessionStorage.getItem('hasAsked');

    if (groups?.length) {
      if (!hasActiveGroup && !storedQrCode && !hasAsked) {
        sessionStorage.setItem('hasAsked', 1);
        openConfirm({
          title: translate('no_active_group'),
          subTitle: translate('seems_no_active_group'),
          onConfirm: () => setOpenQrReader(true),
        });
      } else if (storedQrCode) {
        joinGroup(storedQrCode);
        // } else if (hasActiveGroup && storedQrCode) {
        //   enqueueSnackbar(translate('already_in_group'), {
        //     variant: 'warning',
        //     autoHideDuration: 3000,
        //   });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (qrCode) {
      joinGroup(qrCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode]);

  return (
    <>
      <IconButton onClick={() => setOpenQrReader(true)}>
        <Iconify icon="lucide:scan-line" />
      </IconButton>

      <CustomModal
        fullScreen={!isMdUp}
        open={openQrReader}
        onClose={() => setOpenQrReader(false)}
        sx={{ '& button': { color: 'white' } }}
      >
        {/* {openQrReader ? ( */}
        <QRCodeReader
          onScan={(data) => {
            setQrCode(data);
            setOpenQrReader(false);
          }}
        />
        {/* ) : (
          ''
        )} */}
      </CustomModal>
    </>
  );
};
