import { Alert, Box, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { BrowserQRCodeReader } from '@zxing/browser';
import Image from './Image';
import useLocales from '../hooks/useLocales';
import Iconify from './Iconify';

// Function to resize the image on canvas
const resizeImage = (image, maxWidth, maxHeight) => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Calculate the new width and height while maintaining aspect ratio
  let width = image.width;
  let height = image.height;

  if (width > maxWidth || height > maxHeight) {
    const aspectRatio = width / height;
    if (width > height) {
      width = maxWidth;
      height = width / aspectRatio;
    } else {
      height = maxHeight;
      width = height * aspectRatio;
    }
  }

  // Set the canvas size and draw the image on the canvas
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, width, height);

  return canvas;
};

const QRCodeReader = ({ onScan = () => {}, onError = () => {}, ...others }) => {
  const { translate } = useLocales();

  // const [devices, setDevices] = useState([]);

  // const [selectedDeviceId, setSelectedDeviceId] = useState('');

  const [error, setError] = useState('');

  const [hasCameraAccess, setHasCameraAccess] = useState(true);

  const [permissionDenied, setPermissionDenied] = useState(false);

  // const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0);

  const [facingMode, setFacingMode] = useState('environment');

  const [isLoadingCam, setIsLoadingCam] = useState(false);

  const uploadQrRef = useRef(null);

  // const streamRef = useRef(null);

  // const camContraints = useMemo(() => {
  //   let _def = { facingMode: 'environment' };

  //   if (selectedDeviceId) {
  //     _def = { deviceId: selectedDeviceId };
  //   }

  //   _def.frameRate = '30';

  //   return _def;
  // }, [selectedDeviceId]);

  /**
   * Special case for this project, qrcode will always return url pattern https://url.com?aff=123
   * @returns String
   */
  const getAffCode = (url) => {
    if (!url) return '';
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get('aff');
  };

  // const getDevices = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
  //     streamRef.current = stream;

  //     setPermissionDenied(false);

  //     // Check if mediaDevices and getUserMedia are available
  //     if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
  //       setHasCameraAccess(false);
  //       return;
  //     }

  //     // Get the list of video input devices (cameras)
  //     navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
  //       const videoDevices = deviceInfos.filter((device) => device.kind === 'videoinput');
  //       setDevices(videoDevices);
  //       // if (videoDevices.length > 0 && !isMobile) {
  //       //   setSelectedDeviceId(videoDevices[0].deviceId);
  //       // }
  //     });
  //   } catch (err) {
  //     if (err.name === 'NotAllowedError' || err.name === 'SecurityError') {
  //       setPermissionDenied(true);
  //     } else {
  //       console.error('Error accessing media devices.', err);
  //       setHasCameraAccess(false);
  //     }
  //   }
  // };

  // const stopCameras = () => {
  //   if (streamRef.current) {
  //     streamRef.current.getTracks().forEach((track) => track.stop());
  //     streamRef.current = null;
  //   }
  // };

  // useEffect(() => {
  //   getDevices();

  //   return () => {
  //     setSelectedDeviceId('');
  //     stopCameras();
  //     setCurrentDeviceIndex(0);
  //   };
  // }, []);

  const handleScan = (data) => {
    console.log(data);

    const affCode = getAffCode(data);
    if (affCode) {
      onScan(affCode);
    }
  };

  const handleError = (err) => {
    console.error(err);

    if (err.name === 'NotAllowedError' || err.name === 'SecurityError') {
      setPermissionDenied(true);
    } else {
      console.error('Error accessing media devices.', err);
      setHasCameraAccess(false);
    }

    // setError(err?.message);

    onError(err?.message);
  };

  const handleCycleDevice = () => {
    setIsLoadingCam(true);
    setFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
    setTimeout(() => setIsLoadingCam(false), 1000);
    // if (devices.length === 0) return;

    // const nextIndex = (currentDeviceIndex + 1) % devices.length;
    // setCurrentDeviceIndex(nextIndex);
    // setSelectedDeviceId(devices[nextIndex].deviceId);
  };

  const onUploadClick = () => {
    uploadQrRef.current.value = '';
    setError('');
    if (uploadQrRef.current) uploadQrRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const imageUrl = e.target.result;

          const image = document.createElement('img');
          image.src = imageUrl;

          image.onload = async () => {
            const resizedCanvas = resizeImage(image, 600, 600);

            const codeReader = new BrowserQRCodeReader();
            try {
              const result = await codeReader.decodeFromCanvas(resizedCanvas);
              handleScan(result.getText());
              setError('');
            } catch (err) {
              setError(translate('qr_cant_decode'));
            }
          };
        } catch (error) {
          setError(translate('no_qr_found'));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  if (permissionDenied) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {translate('camera_access_denied')}
      </Alert>
    );
  }

  if (!hasCameraAccess) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {translate('camera_not_available')}
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'center',
        flexDirection: 'column',
        gap: 1,
        pt: 7,
        height: '100%',
      }}
      {...others}
    >
      {error && (
        <Alert
          severity="error"
          sx={{ m: 2 }}
          onClose={() => {
            setError('');
          }}
        >
          {error}
        </Alert>
      )}
      {/* {selectedDeviceId && ( */}
      <Box sx={{ height: 'auto', width: '100%', position: 'relative' }}>
        <QrReader
          key={facingMode}
          onResult={(result, error) => {
            if (result) {
              handleScan(result?.text);
            }

            if (error?.message) {
              handleError(error);
            }
          }}
          containerStyle={{ height: 'auto', width: '100%' }}
          videoContainerStyle={{ paddingTop: 'unset' }}
          videoStyle={{ height: '100%', width: '100%', position: 'unset' }}
          constraints={{ facingMode, frameRate: '30' }}
          // constraints={{ deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined, frameRate: '30' }}
        />

        <Image
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: { xs: '80%', md: '60%' },
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            boxSizing: 'border-box',
            pointerEvents: 'none',
            opacity: 0.5,
          }}
          src={require('../assets/scan-outline.png')}
        />
        <Box sx={{ position: 'absolute', p: 2, bottom: 0, left: 0 }}>
          <Button startIcon={<Iconify icon={'solar:upload-bold'} />} variant="contained" onClick={onUploadClick}>
            {translate('upload_qr_code')}
          </Button>
          <input
            ref={uploadQrRef}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </Box>
      </Box>
      {/* )} */}

      {/* <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleCycleDevice} disabled={isLoadingCam}>
          <Iconify icon="teenyicons:refresh-solid" sx={{ fontSize: '25px' }} />
        </Button>
      </Box> */}

      {/* <TextField
        fullWidth
        size="small"
        select
        SelectProps={{ native: true }}
        onChange={handleDeviceChange}
        value={selectedDeviceId}
        sx={{ p: 2 }}
      >
        {devices.map((device, index) => (
          <option key={index} value={device.deviceId}>
            {device.label || `Camera ${index + 1}`}
          </option>
        ))}
      </TextField> */}
    </Box>
  );
};

export default QRCodeReader;
